import React, { useEffect, useState } from "react";

const TabToggler = (props) => {
  const { tabs, children, onTabChange, activeTab } = props;

  const handleTabClick = (tab) => {
    onTabChange(tab);
  };
  useEffect(() => {
    console.log(activeTab);
  }, [activeTab]);

  return (
    <div style={styles.container}>
      <div style={styles.tabHeader}>
        {tabs.map((tab) => (
          <button
            key={tab}
            style={{
              ...styles.tabButton,
              ...(activeTab === tab ? styles.activeTab : {}),
            }}
            onClick={() => handleTabClick(tab)}
          >
            {tab}
          </button>
        ))}
      </div>

      <div style={styles.tabContent}>{children}</div>
    </div>
  );
};

const styles = {
  container: {
    // width: "300px",
    margin: "0 auto",
    border: "1px solid #ccc",
    borderRadius: "4px",
    padding: "10px",
  },
  tabHeader: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: "10px",
  },
  tabButton: {
    width: "100%",
    padding: "10px 20px",
    cursor: "pointer",
    backgroundColor: "#f0f0f0",
    border: "none",
    outline: "none",
    borderRadius: "4px",
  },
  activeTab: {
    backgroundColor: "#007BFF",
    color: "#fff",
  },
  tabContent: {
    padding: "10px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
  },
};

export default TabToggler;
