import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import axiosInstance from "../../../../api/axios_instance";
import { Headers } from "../../../../api/actions";
import { Api } from "../../../../api";
import swal from "sweetalert";

const SurveyList = (props) => {
  const { ArrayData } = props;
  const [val, setVal] = useState(1);
  const [err, setErr] = useState("");

  useEffect(() => {
    const options = {
      method: "get",
      url: `${Api}Setting/GetLongTermValue`,
      headers: Headers,
    };
    axiosInstance(options)
      .then(function (response) {
        setVal(+response.data.responseObject);
      })
      .catch(function (error) {});
  }, []);

  useEffect(() => {
    if (isNaN(+val) || Math.floor(+val) !== +val || +val <= 0)
      setErr("يجب ادخال عدد صحيح موجب");
    else setErr("");
  }, [val]);

  const submit = () => {
    if (err) return;
    const options = {
      method: "post",
      url: `${Api}Setting/EditLongTermValue`,
      headers: Headers,
      data: { longTermNumber: +val },
    };
    axiosInstance(options)
      .then(function (response) {
        swal("تم  التعديل بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
      })
      .catch(function (error) {});
  };
  return (
    <div className="survey-list">
      <div className="survey-list__top">
        <h3> الاستبيانات </h3>
      </div>
      <div className="survey-list__content">
        <div className="exams-content-add__form__card__inputs__rows__item">
          <label>عدد المواد فى الدورات طويلة المدي</label>
          <br />
          <label style={{ fontSize: "small", color: "gray" }}>
            الدورات التي تحتوي علي هذا العدد من المواد او اكثر هي طويلة المدي
          </label>
          <br />

          <div className="input-test-duration" style={{ maxWidth: "20rem" }}>
            <input
              style={{ border: "none" }}
              type="number"
              placeholder="عدد المواد"
              value={val}
              onChange={(e) => {
                setVal(e.target.value);
              }}
            />
          </div>
          {err && (
            <>
              <br />
              <span className="error-text">{err}</span>
              <br />
            </>
          )}
          <div className="add-question-content__card__buttons">
            <button onClick={submit} type="button" className="btn btn-save">
              <span>حفظ</span>
            </button>
          </div>
        </div>
      </div>

      <div className="survey-list__content">
        {ArrayData.map((item, index) => {
          return (
            <div className="survey-list__content__item" key={index}>
              <div className="survey-list__content__item__left">
                <p>{item.name}</p>
              </div>
              <div className="survey-list__content__item__right">
                <NavLink
                  to={`/survey/survey-view/${
                    item.id
                  }/page:1/search:${""}/sortclassroom:${""}`}
                  className="btn btn-show"
                  title="عرض الاستبيانات"
                >
                  <img src="/assets/icons/eye-show.svg" alt="edit" />
                </NavLink>
                <NavLink
                  to={`/survey/viewquestion/${item.id}`}
                  className="btn btn-edit"
                  title="تعديل اسئلة الاستبيانات"
                >
                  <img src="/assets/icons/icon-edit.svg" alt="edit" />
                </NavLink>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SurveyList;
