import React, { useRef } from "react";
import axios from "axios";
import { Api } from "../../../../api/index.js";
import { Headers } from "../../../../api/actions.js";
import swal from "sweetalert";

import { NavLink, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axiosInstance from "../../../../api/axios_instance.jsx";

const AddAllQuestionTable = (props) => {
  const { ArrayData, perpage, pagenumber, setLoading, PageSize, setPageSize } =
    props;
  const { id } = useParams();
  let navigate = useNavigate();

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    navigate(`/questionbanck/add-all/page:${number}/${id}`);
    setLoading(false);
  };
  return (
    <div className="table-container">
      <AddAllQuestionTableHead />
      <>
        <ul className="table-container__body">
          {ArrayData.map((item) => {
            return (
              <li key={item.id}>
                <AddAllQuestionTableBody Item={item} subjectId={id} />
              </li>
            );
          })}
        </ul>
        <Paginate
          handlePageClick={handlePageClick}
          perpage={perpage}
          pagenumber={pagenumber}
          setLoading={setLoading}
          PageSize={PageSize}
          setPageSize={setPageSize}
        />
      </>
    </div>
  );
};

export default AddAllQuestionTable;

const AddAllQuestionTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span className="justify-content-start">
          <label htmlFor="horns"> نوع السؤال</label>
        </span>
        <span>نص السؤال </span>
        <span className="justify-content-end">الإجراءات</span>
      </li>
    </ul>
  );
};

const AddAllQuestionTableBody = (props) => {
  const { subjectId, Item } = props;

  return (
    <>
      <span className="justify-content-start">
        <label htmlFor="horns">{Item.questionTypeName} </label>
      </span>
      <span className="text-end">{Item.name}</span>
      <span className="justify-content-end">
        {Item.questionTypeId === 3 ? (
          <NavLink
            type="button"
            to={`/questionbanck/edite-question-matching/${Item.id}/subjectid:${subjectId}`}
            className="btn btn-edit"
            title="عرض السؤال"
          >
            <img src="/assets/icons/eye-show.svg" alt="edit" />
          </NavLink>
        ) : (
          <NavLink
            type="button"
            to={`/questionbanck/edite-question/${Item.id}/subjectid:${subjectId}`}
            className="btn btn-edit"
            title="عرض السؤال"
          >
            <img src="/assets/icons/eye-show.svg" alt="edit" />
          </NavLink>
        )}
        <button
          type="button"
          className="btn btn-remove"
          data-bs-toggle="modal"
          data-bs-target={`#delete-question-${Item.id}`}
        >
          <img src="/assets/icons/icon-delete.svg" alt="remove" />
        </button>
      </span>
      <ModalDelete Item={Item} />
    </>
  );
};

function Paginate(props) {
  const {
    handlePageClick,
    perpage,
    pagenumber,
    setLoading,
    PageSize,
    setPageSize,
  } = props;
  const SelectValue = [
    {
      id: 1,
      value: 10,
      title: "10 صفوف",
    },
    {
      id: 2,
      value: 20,
      title: "20 صفوف",
    },
    {
      id: 3,
      value: 30,
      title: "30 صفوف",
    },
    {
      id: 4,
      value: 40,
      title: "40 صفوف",
    },
    {
      id: 5,
      value: 50,
      title: "50 صفوف",
    },
  ];

  return (
    <div className="paginate_control">
      <div className="paginate_control__select">
        <label htmlFor="">
          <span>عدد الصفوف</span>
        </label>
        <select
          className="form-select"
          value={PageSize}
          onChange={(e) => {
            setPageSize(parseInt(e.target.value));
            setLoading(false);
          }}
        >
          <>
            {SelectValue.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </>
        </select>
      </div>
      <ReactPaginate
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#64748B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#94A3B8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        breakLabel={"..."}
        pageCount={perpage}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
        forcePage={pagenumber - 1}
      />
    </div>
  );
}

const ModalDelete = (props) => {
  const { id } = useParams();

  const { Item } = props;
  const cancelRef = useRef();
  return (
    <div
      className="modal fade"
      id={`delete-question-${Item.id}`}
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header border-0 mb-3 mt-3">
            <h5
              id="staticBackdropLabel"
              className="w-100 text-center modal-title"
            >
              حذف السؤال
            </h5>
          </div>
          <div className="modal-body border-0 mb-3">
            <p className="w-100 text-center">
              هل أنت متأكد من حذف هذا السؤال ؟ .
            </p>
          </div>
          <div className="modal-footer border-0 mb-3 d-flex justify-content-center align-items-center">
            <button
              ref={cancelRef}
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              إلغاء
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                DeletQuestion(Item, id).then(() => cancelRef.current.click());
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DeletQuestion = async (item, subjectId) => {
  const options =
    item.questionTypeId === 3
      ? {
          method: "post",
          url: `${Api}Question/DeleteMatchingQuestion`,
          headers: Headers,
          data: { subjectId, groupId: item.groupId },
        }
      : {
          method: "post",
          url: `${Api}Question/DeleteQuestion`,
          headers: Headers,
          data: { id: item.id },
        };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
        return;
      } else {
        swal("تم الحذف بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
      }
    })
    .catch(function (error) {});
};
