import axios from "axios";
import { Api } from "../../../api/index.js";
import { Headers } from "../../../api/actions.js";

import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { downloadBase64File } from "../../../components/shared/utils.js";
import axiosInstance from "../../../api/axios_instance.jsx";

const ExamsTable = (props) => {
  const { ArrayData, perpage, pagenumber, setLoading, PageSize, setPageSize } =
    props;
  const { search } = useParams();
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  let navigate = useNavigate();
  const [selectedTest, setSelectedTest] = useState(null);

  const handlePageClick = (data) => {
    let number = 1 + data.selected;
    navigate(`/exams/page:${number}/search:${searchKeyword}`);
    setLoading(false);
  };
  return (
    <div className="table-container">
      <ExamsTableHead />
      <>
        <ul className="table-container__body">
          {ArrayData.map((item) => {
            return (
              <li key={item.id}>
                <ExamsTableBody Item={item} setSelectedTest={setSelectedTest} />
              </li>
            );
          })}
        </ul>
        <ModalExtendTime Id={selectedTest} />
        <Paginate
          handlePageClick={handlePageClick}
          perpage={perpage}
          pagenumber={pagenumber}
          setLoading={setLoading}
          PageSize={PageSize}
          setPageSize={setPageSize}
        />
      </>
    </div>
  );
};

export default ExamsTable;

const ExamsTableHead = () => {
  return (
    <ul className="table-container__head">
      <li>
        <span className="justify-content-start">رقم الدورة</span>
        <span>اسم الدورة</span>
        <span>رمز المادة</span>
        <span>اسم المادة</span>
        <span>تاريخ الإنشاء</span>
        <span>تاريخ الاختبار</span>
        <span className="justify-content-end">الإجراءات</span>
      </li>
    </ul>
  );
};

const ExamsTableBody = (props) => {
  const { Item, setSelectedTest } = props;
  return (
    <>
      <span className="justify-content-start">{Item.classRoomCode}</span>

      <span>{Item.classRoomName} </span>
      <span>{Item.subjectCode}</span>
      <span>{Item.subjectName}</span>
      <span>{Item.creationDate}</span>
      <span>{Item.testDate}</span>
      <span className="justify-content-end">
        <NavLink
          to={`/exams/edit/${Item.id}/subjectid:${Item.subjectId}/classroomid:${Item.classRoomId}`}
          className="btn btn-edit"
          title="تعديل"
        >
          <img src="/assets/icons/icon-edit.svg" alt="edit" />
        </NavLink>
        <NavLink
          to={`/exams/list-exams/${Item.id}/subjectid:${Item.subjectId}/classroomid:${Item.classRoomId}`}
          className="btn btn-show"
          title="تفاصيل"
        >
          <img src="/assets/icons/eye-show.svg" alt="details" />
        </NavLink>
        <button
          type="button"
          className="btn btn-remove"
          data-bs-toggle="modal"
          data-bs-target={`#ModalDeleteExams-${Item.id}`}
          title="حذف"
        >
          <img src="/assets/icons/icon-delete.svg" alt="remove" />
        </button>
        <button
          type="button"
          className="btn btn-show"
          data-bs-toggle="modal"
          data-bs-target={`#ExtendTime`}
          onClick={() => setSelectedTest(Item.id)}
          title="اضافة وقت"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            style={{ padding: "6px" }}
          >
            <path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z" />
          </svg>
          {/* <img src="/assets/icons/icon-delete.svg" alt="extend time" /> */}
        </button>
        <button
          className="btn btn-show"
          title="تحميل pdf"
          onClick={() => {
            DownloadExams(Item.id);
          }}
        >
          <img
            src="/assets/icons/pdf.png"
            alt="edit"
            style={{
              filter: "none",
            }}
          />
        </button>
      </span>
      <ModalDeleteExams Id={Item.id} />
    </>
  );
};

function Paginate(props) {
  const {
    handlePageClick,
    perpage,
    pagenumber,
    setLoading,
    PageSize,
    setPageSize,
  } = props;
  const SelectValue = [
    {
      id: 1,
      value: 10,
      title: "10 صفوف",
    },
    {
      id: 2,
      value: 20,
      title: "20 صفوف",
    },
    {
      id: 3,
      value: 30,
      title: "30 صفوف",
    },
    {
      id: 4,
      value: 40,
      title: "40 صفوف",
    },
    {
      id: 5,
      value: 50,
      title: "50 صفوف",
    },
  ];

  return (
    <div className="paginate_control">
      <div className="paginate_control__select">
        <label htmlFor="">
          <span>عدد الصفوف</span>
        </label>
        <select
          className="form-select"
          value={PageSize}
          onChange={(e) => {
            setPageSize(parseInt(e.target.value));
            setLoading(false);
          }}
        >
          <>
            {SelectValue.map((item, index) => {
              return (
                <option key={index} value={item.value}>
                  {item.title}
                </option>
              );
            })}
          </>
        </select>
      </div>
      <ReactPaginate
        previousLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M9 18L15 12L9 6"
              stroke="#64748B"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        nextLabel={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M15 6L9 12L15 18"
              stroke="#94A3B8"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        breakLabel={"..."}
        pageCount={perpage}
        postsPerPage={2}
        pageSize={1}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        renderOnZeroPageCount={null}
        forcePage={pagenumber - 1}
      />
    </div>
  );
}

const DeletQuestion = async (id) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Test/DeleteTest`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
        return;
      } else {
        swal("تم الحذف بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
      }
    })
    .catch(function (error) {});
};

const ExtendTime = async (id, time) => {
  var data = new FormData();
  data.append("testId", id);
  data.append("minutes", time);

  const options = {
    method: "post",
    url: `${Api}Test/ExtendTestTime`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      if (response.data.responseStatus.description === "Error") {
        swal(response.data.responseStatus.errors[0].errorMessage, {
          icon: "error",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
        return;
      } else {
        swal("تم مد وقت الامتحان بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        }).then((result) => {
          if (result === true) {
            window.location.reload();
          }
        });
      }
    })
    .catch(function (error) {});
};

const ModalDeleteExams = (props) => {
  const { Id } = props;
  return (
    <div
      className="modal fade modal-edite-exams"
      id={`ModalDeleteExams-${Id}`}
      tabIndex="-1"
      aria-labelledby="ModalDeleteExamsLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="header">
            <h6>هل تريد حذف الاختبار ؟</h6>
          </div>

          <div className="form-group-button">
            <button
              type="button"
              className="btn btn-line-susees"
              data-bs-dismiss="modal"
            >
              إلغاء
            </button>
            <button
              type="button"
              className="btn btn-submit"
              onClick={(e) => {
                e.preventDefault();
                DeletQuestion(Id);
              }}
            >
              حذف
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
const ModalExtendTime = (props) => {
  const { Id } = props;
  const [time, setTime] = useState("0");
  const [err, setErr] = useState("");
  return (
    <div
      className="modal fade modal-edite-exams"
      id={`ExtendTime`}
      tabIndex="-1"
      aria-labelledby="ModalExtendTime"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="header">
            <div className="exams-content-add__form__card__inputs__rows__item">
              <label>المدة المضافة للاختبار</label>
              <div className="input-test-duration">
                <input
                  style={{ border: "none" }}
                  type="number"
                  placeholder="مدة الاختبار"
                  value={time}
                  onChange={(e) => {
                    setTime(e.target.value);
                    if (
                      isNaN(+e.target.value) ||
                      +e.target.value <= 0 ||
                      +e.target.value !== Math.floor(+e.target.value)
                    )
                      setErr("يجب ان يكون الوقت المضاف عدد صحيح موجب");
                    else setErr("");
                  }}
                />
                <span className="text" style={{ margin: "0 20px" }}>
                  دقيقة
                </span>
              </div>
              {err && (
                <>
                  <br />
                  <span className="error-text">{err}</span>
                  <br />
                </>
              )}
            </div>
          </div>

          <div className="form-group-button">
            <button
              type="button"
              className="btn btn-line-susees"
              data-bs-dismiss="modal"
              onClick={() => {
                setTime("0");
                setErr("");
              }}
            >
              إلغاء
            </button>
            <button
              type="button"
              className="btn btn-submit"
              onClick={(e) => {
                e.preventDefault();
                if (err) return;
                if (isNaN(+time) || +time <= 0 || +time !== Math.floor(+time)) {
                  setErr("يجب ان يكون الوقت المضاف عدد صحيح موجب");
                  return;
                }
                ExtendTime(Id, time);
              }}
            >
              اضافة وقت
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const DownloadExams = (id) => {
  var data = new FormData();
  data.append("id", id);

  const options = {
    method: "post",
    url: `${Api}Test/ExportTest`,
    headers: Headers,
    data,
  };
  axiosInstance(options)
    .then(function (response) {
      const { fileContent, fileName } = response.data;
      downloadBase64File(fileContent, fileName, "application/pdf");
    })
    .catch(function (error) {});
};
