/* eslint-disable eqeqeq */
import React from "react";
import { Api } from "../../../api";
import { Headers } from "../../../api/actions";
import axios from "axios";
import swal from "sweetalert";
import axiosInstance from "../../../api/axios_instance";

export const NotificationsContent = (props) => {
  const { ArrayData } = props;
  console.log(ArrayData);

  return (
    <div className="notifications-content">
      {ArrayData.map((item, index) => (
        <div className="notifications-content__card" key={index}>
          <NotificationsContentCard Item={item} Index={index} />
        </div>
      ))}
      {/* <ModalNotfications /> */}
    </div>
  );
};

const NotificationsContentCard = (props) => {
  const { Item, Index } = props;
  return (
    <>
      <h6>خروج من الاختبار بالخطأ</h6>
      <div className="line"></div>
      <ul>
        <li>
          <span className="title">اسم الطالب</span>
          <span className="value">{Item.studentName}</span>
        </li>
        <li>
          <span className="title">رقم الهوية</span>
          <span className="value">{Item.idNumber}</span>
        </li>
        <li>
          <span className="title">رمز الدورة</span>
          <span className="value">{Item.classRoomNumber}</span>
        </li>
        <li>
          <span className="title">رقم الدورة</span>
          <span className="value">{Item.classRoomCode}</span>
        </li>
        <li>
          <span className="title">اسم المادة</span>
          <span className="value">{Item.subjectName}</span>
        </li>
      </ul>

      <div className="line"></div>
      <button
        type="button"
        className="btn"
        data-bs-toggle="modal"
        data-bs-target={`#ModalNotifications-${Index}`}
      >
        <span>إعادة الطالب للاختبار</span>
      </button>
      <ModalNotfications Index={Index} Item={Item} />
    </>
  );
};

export const ModalNotfications = (props) => {
  const { Index, Item } = props;
  console.log(Item);

  return (
    <div
      className="modal fade modal-notifications"
      id={`ModalNotifications-${Index}`}
      tabIndex="-1"
      aria-labelledby={`ModalNotificationsLabel-${Index}`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <h6>خروج من الاختبار بالخطأ</h6>
          <div className="line"></div>
          <ul>
            <li>
              <span className="title">اسم الطالب</span>
              <span className="value">{Item.studentName}</span>
            </li>
            <li>
              <span className="title">رقم الهوية</span>
              <span className="value">{Item.idNumber}</span>
            </li>
          </ul>
          <ul>
            <li>
              <span className="title">رمز الدورة</span>
              <span className="value">{Item.classRoomNumber}</span>
            </li>
            <li>
              <span className="title">رقم الدورة</span>
              <span className="value">{Item.classRoomCode}</span>
            </li>
            <li>
              <span className="title">اسم المادة</span>
              <span className="value">{Item.subjectName}</span>
            </li>
          </ul>
          <div className="line"></div>
          <div className="buttons">
            <button
              type="button"
              className="btn"
              onClick={() => {
                SendData(Item);
              }}
            >
              <span>إعادة الطالب للاختبار</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SendData = async (Data) => {
  var data = new FormData();
  data.append("studentId", Data.studentId);
  data.append("testId", Data.testId);
  const options = {
    method: "post",
    url: `${Api}Test/ReturnStudentToTest`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      console.log(response);
      if (response.data.responseStatus.description == "Success") {
        swal("تم أعاده الطالب للأمتحان بنجاح", {
          icon: "success",
          confirmButtonColor: "#d33",
          button: {
            text: "إغلاق",
            closeModal: true,
          },
        });
        window.location.reload();
      }
    })
    .catch(function (error) {});
};
