import React, { useState } from "react";
import { Headers } from "../../../api/actions";
import { Api } from "../../../api";
import axios from "axios";
import { useDispatch } from "react-redux";
import { signIn } from "../../../features/user/userSlice";
import { useNavigate } from "react-router";
import axiosInstance from "../../../api/axios_instance";
import swal from "sweetalert";

const SinginForm = () => {
  const [number, setNumber] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const SingInData = async (e) => {
    e.preventDefault();
    // https://www.etsapp.somee.com/api/'
    const options = {
      method: "POST",
      url: `${Api}ApplicationDbUser/Login`,
      headers: Headers,
      data: { userName: number, password },
    };
    await axiosInstance(options)
      .then(function (response) {
        if (response.data.responseStatus.code !== 0) {
          swal(
            response.data.responseStatus.errors
              .map((err) => err.errorMessage)
              .join("\n"),
            {
              icon: "error",
              button: {
                text: "إغلاق",
                closeModal: true,
              },
            }
          );
        } else {
          const {
            fullName: username,
            userName: militaryNumber,
            roles,
            token,
            userMenu,
          } = response.data.responseObject;
          const menu = userMenu.map((itm) => itm.menuName);
          dispatch(signIn({ username, roles, token, menu, militaryNumber }));
          navigate("/");
        }
        console.log(response);
      })
      .catch(function (error) {});
  };

  return (
    <div className="singin__content__form">
      <div className="singin__content__form__title">
        <img src="/assets/images/logo-light.jfif" alt="" />
        <h2>مرحباً بعودتك !</h2>
        <p>تسجيل الدخول إلى حسابك</p>
      </div>

      <form action="" onSubmit={SingInData}>
        <InputNumber number={number} setNumber={setNumber} />
        <InputPassword password={password} setPassword={setPassword} />

        <div className="form-group-button">
          <button type="submit" className="btn">
            <span>تسجيل الدخول</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default SinginForm;

const InputNumber = (props) => {
  const { number, setNumber } = props;

  const OnChinge = (e) => {
    e.preventDefault();
    setNumber(e.target.value);
  };
  return (
    <div className="input-group-number">
      <img src="/assets/icons/fi_tablet.png" alt="" />
      <input
        type="number"
        placeholder="الرقم العسكري"
        value={number}
        onChange={(e) => {
          OnChinge(e);
        }}
      />
    </div>
  );
};

const InputPassword = (props) => {
  const { password, setPassword } = props;
  const [toggle, setToggle] = useState(false);

  const OnChinge = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };
  return (
    <div className="input-group-password">
      <img src="/assets/icons/icon-lock.png" alt="" />
      <input
        type={toggle === false ? "password" : "text"}
        placeholder="كلمة المرور"
        value={password}
        onChange={(e) => {
          OnChinge(e);
        }}
      />
      <span className="toggoleimg" onClick={() => setToggle(!toggle)}>
        {toggle === false ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clipPath="url(#clip0_761_6712)">
              <path
                d="M6.60008 2.82664C7.05897 2.71923 7.52879 2.66554 8.00008 2.66664C12.6667 2.66664 15.3334 7.99998 15.3334 7.99998C14.9287 8.75705 14.4461 9.4698 13.8934 10.1266M9.41341 9.41331C9.23032 9.60981 9.00951 9.76741 8.76418 9.87673C8.51885 9.98604 8.25402 10.0448 7.98547 10.0496C7.71693 10.0543 7.45019 10.0049 7.20115 9.9043C6.95212 9.80371 6.7259 9.654 6.53598 9.46408C6.34606 9.27416 6.19634 9.04794 6.09575 8.7989C5.99516 8.54987 5.94577 8.28312 5.9505 8.01458C5.95524 7.74604 6.01402 7.48121 6.12333 7.23587C6.23264 6.99054 6.39025 6.76974 6.58675 6.58664M11.9601 11.96C10.8205 12.8286 9.43282 13.3099 8.00008 13.3333C3.33341 13.3333 0.666748 7.99998 0.666748 7.99998C1.49601 6.45457 2.64617 5.10438 4.04008 4.03998L11.9601 11.96Z"
                stroke="black"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M0.666748 0.666656L15.3334 15.3333"
                stroke="black"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_761_6712">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="12"
            viewBox="0 0 14 12"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.10755 5.86813C9.10755 7.03213 8.16355 7.97547 6.99955 7.97547C5.83555 7.97547 4.89221 7.03213 4.89221 5.86813C4.89221 4.70347 5.83555 3.76013 6.99955 3.76013C8.16355 3.76013 9.10755 4.70347 9.10755 5.86813Z"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.99859 10.736C9.53725 10.736 11.8593 8.91067 13.1666 5.868C11.8593 2.82533 9.53725 1 6.99859 1H7.00125C4.46258 1 2.14059 2.82533 0.833252 5.868C2.14059 8.91067 4.46258 10.736 7.00125 10.736H6.99859Z"
              stroke="#130F26"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </span>
    </div>
  );
};
