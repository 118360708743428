import React, { useEffect, useState } from "react";
import { Api } from "../../../api";
import axios from "axios";
import { Headers } from "../../../api/actions";
import Loading from "../../../components/loading/loading";

import NavbarTop from "../../../components/navbar/navbar_top";
import NavbarLeft from "../../../components/navbar/navbar_left";
import { useParams } from "react-router";
import SubjectsContent from "../component/subjects-content";
import axiosInstance from "../../../api/axios_instance";

const Subjects = () => {
  const { id, page, search } = useParams();
  const pageCount = +page.slice(page.indexOf(":") + 1, page.length);
  const searchKeyword = search.slice(search.indexOf(":") + 1, search.length);
  const [perpage, setPerpage] = useState(0);
  const [data, setData] = useState([]);
  const [PageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    GetData(
      id,
      setData,
      setLoading,
      pageCount,
      searchKeyword,
      setPerpage,
      PageSize
    );
  }, [id, pageCount, searchKeyword, PageSize]);

  const ObJectArrayData = [
    {
      id: 2,
      href: `/courses/page:${1}/search:${""}/active:`,
      title: "الدورات",
    },
  ];

  if (!loading) {
    return (
      <section className="main">
        <NavbarLeft />
        <div className="main-body">
          <NavbarTop ArrayData={ObJectArrayData} />
          <div className="main-body__content">
            <Loading />
          </div>
        </div>
      </section>
    );
  }
  return (
    <section className="main">
      <NavbarLeft />
      <div className="main-body">
        <NavbarTop ArrayData={ObJectArrayData} />
        <div className="main-body__content">
          <SubjectsContent
            ArrayData={data}
            perpage={perpage}
            pagenumber={pageCount}
            setLoading={setLoading}
            PageSize={PageSize}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </section>
  );
};

export default Subjects;

const GetData = async (
  id,
  setData,
  setLoading,
  pageCount,
  searchKeyword,
  setPerpage,
  PageSize
) => {
  var data = new FormData();
  data.append("classRoomId", id);
  data.append("pageNumber", pageCount);
  data.append("searchKeyword", searchKeyword);
  data.append("pageSize", PageSize);
  // data.append("subjectId", null);
  const options = {
    method: "post",
    url: `${Api}Subject/GetSubjectByClassRoom`,
    headers: Headers,
    data,
  };
  await axiosInstance(options)
    .then(function (response) {
      setData(response.data.responseObject.items);
      setLoading(true);

      setPerpage(
        Math.ceil(
          response.data.responseObject.totalItemsCount /
            response.data.responseObject.pageSize
        )
      );
    })
    .catch(function (error) {
      setLoading(true);
    });
};
